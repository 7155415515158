@import '../../scss/imports';

.wizard-step {
  color: #121212;
  font-size: 1rem;
  margin: 6.25rem auto;
  max-width: 42.75rem;
  padding-bottom: 25rem;

  .wizard-email-container {
    display: flex;
    justify-content: space-between;

    button {
      margin: 0;
      width: 20.625rem;
    }

    #person-wizard-registration-later {
      background-color: #e9e9e9;
      color: #000;
    }
  }

  .centered {
    display: flex;
    justify-content: center;
  }

  .wizard-step-header-container {
    margin-bottom: 2.625rem;
  }

  .wizard-step-header {
    font-size: 1.375rem;
    font-weight: bold;
    margin-bottom: .75rem;
    text-align: start;
  }

  .wizard-step-subheader {
    font-size: 1rem;
    text-align: start;
  }

  .wizard-select {
    text-transform: capitalize;
    width: 100%;
  }

  .wizard-select__value-container {
    min-height: 3.5rem;
  }

  .wizard-select__menu {
    margin-top: 0;
  }

  .wizard-select__indicator-separator {
    display: none;
  }

  .wizard-select__control:hover {
    border-color: $primary-color;
  }

  .wizard-select__control--is-focused {
    border-color: $primary-color;
    box-shadow: none;
  }

  .wizard-select__option {
    font-size: .875rem;
    min-height: 2.5rem;
    text-align: start;
  }

  .wizard-select__option--is-focused {
    background-color: rgba(98, 0, 238, .1);
    color: $text-color;
  }

  .wizard-select__option--is-selected {
    background-color: rgba(98, 0, 238, .3);
    color: $text-color;

  }

  .wizard-select__option:active {
    background-color: rgba(98, 0, 238, .2);
    color: $text-color;
  }

  .wizard-select__dropdown-indicator {
    display: none;
  }

  .wizard-select-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: .9375rem;
    width: 100%;

    .wizard-select {
      width: 45%;
    }

    .wizard-select.full {
      width: 100%;
    }

    .wizard-select-organization-container {
      width: 45%;

      .wizard-select {
        margin-bottom: .625rem;
        width: 100%;
      }
    }
  }

  .wizard-text-input {
    background-color: #fff;
    border: 1px solid #e2e2e2;
    box-sizing: border-box;
    height: 3.5rem;
    padding: .3125rem;
    width: 100%;

    hr {
      display: none;
    }

    input {
      font-size: 1rem;
    }
  }

  .wizard-text-multi-input-container {
    align-content: center;
    display: flex;
    justify-content: space-between;

    .wizard-text-input {
      width: 20.625rem;
    }
  }

  .wizard-button {
    background-color: $primary-color;
    border-radius: 3px;
    color: #fff;
    font-weight: bold;
    height: 3.5rem;
    margin: 2.8125rem 0 2.5rem;
    width: 100%;
  }

  .wizard-button:disabled {
    background-color: #eaeaea;
    color: #a8a8a8;
  }

  .wizard-error {
    color: #f44336;
    font-size: 1rem;
    margin-top: .625rem;
  }

  .remove-button {
    height: 2.25rem;
    width: 2.25rem;
  }

  #relation-tree-container {
    border-radius: .25rem;
    margin-bottom: 3.125rem;

    span {
      color: $text-color;
      font-family: $font-light;
      font-size: 1.125rem;
    }
  }

  .operator-alert {
    color: $priority-color;
  }

  .select-alert,
  .operator-alert {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    text-align: left;
  }
}

.confirm-wizard {
  max-width: 64rem;

  .wizard-step-header-container,
  .wizard-email-container {
    margin: auto;
    max-width: 42.75rem;
  }

  .wizard-step-header-container {
    margin-bottom: 1.125rem;
  }
}
